import '@uangcermat/uikit-web/build/index.css';
import 'tailwindcss/tailwind.css';
import '../styles/font.css';

import AppContext from '@context/AppContext';
import { useI18n } from '@hooks/useI18n';
import DashboardLayout from '@layout/DashboardLayout';
import { ColorBlue, ColorDark, ColorLight } from '@uangcermat/uikit-web';
import { appCookies } from '@utils/appCookies';
import { AppTrackingInterface } from 'interface/TrackingInterface';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { track } from 'react-tracking';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { gardakuColorScheme } from 'theme/gardakuColorScheme';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: ${ColorLight.whiteSmoke};
    height: 100%;
    margin:0;
    padding: 0;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  #__next {
    height: 100%;
  }
  .wrapper {
    min-height: 100vh;
    max-width: 500px;
    margin: auto;
    background-color: ${ColorLight.whiteSmoke};
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    position: absolute;
    background-color: ${ColorDark.solitude};
  }
  
  ::-webkit-scrollbar-thumb {
    position: absolute;
    background-color: ${ColorDark.blackCoral}; 
    border-radius: 23px;
  }
  
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    background-color: ${ColorLight.whiteSmoke} !important;
    border-color: ${ColorLight.whiteSmoke} !important;
  }

  .rs-calendar-table-cell-is-today > .rs-calendar-table-cell-content:hover  {
    color: ${ColorDark.blackCoral} !important;
  }
  .rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content,
  .rs-picker-toolbar-right-btn-ok,
  .rs-calendar-time-dropdown-cell-active,
  .rs-calendar-table-cell-selected > .rs-calendar-table-cell-content
  {
    background-color: ${ColorBlue.buttonBlue} !important;
  }

  .rs-picker-disabled {
    opacity: 1;
  }

  .rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
    color: ${ColorDark.blackCoral} !important;
  }
  //FIXME: add props maxHeight on selectPicker
  .rs-picker-menu > div:nth-child(2) > .rs-picker-select-menu-items {
    max-height: 240px !important;
  }

  table {
    td > div > p[label='NOT SPECIFIED'],
    td > div > p[label='TIDAK ADA'] {
      color: ${ColorDark.bermudaGray};
    }
  }
    
  /* Language Item Switcher */
  .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover {
    color: ${ColorBlue.cornFlowerBlue} !important;
    background-color: ${ColorLight.aliceBlue} !important;
  }

  span.rs-picker-toggle-placeholder {
    line-height : 18px !important;
  }
`;
const { getCookie } = appCookies();

const queryCache = new QueryCache({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: async (success: any, query) => {
    const token = await getCookie({
      name: 'access_token'
    });
    if (token && typeof success === 'undefined') {
      query.fetch();
    }
  }
});

// eslint-disable-next-line react-hooks/rules-of-hooks
const { init } = useI18n();

init();

// FIXME: add props types of MyApp, got an issue from nextjs about parse `err` props
// eslint-disable-next-line react/prop-types
const MyApp = ({ Component, pageProps, err }: AppProps & { err: unknown }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: {
          queries: {
            retry: 2,
            refetchOnWindowFocus: false
          }
        }
      })
  );

  const router = useRouter();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <GlobalStyle />
      <div id="root" className="h-full">
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider theme={gardakuColorScheme}>
              <AppContext>
                {[
                  'dashboard',
                  'room',
                  'communication',
                  'activity',
                  'activities',
                  'collection-case',
                  'collection-groups',
                  'planner'
                ].includes(router.pathname.split('/')[1]) ? (
                  <DashboardLayout>
                    <Component {...pageProps} err={err} />
                  </DashboardLayout>
                ) : (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  <Component {...pageProps} err={err} />
                )}
              </AppContext>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </div>
    </>
  );
};

const TrackedApp = track(
  {},
  {
    dispatch: (data: AppTrackingInterface) => {
      const trackProperties = {};

      if (data.properties) {
        Object.assign(trackProperties, data.properties);
      }

      // TODO: send tracker data to server
    }
  }
)(MyApp);

export default dynamic(() => Promise.resolve(TrackedApp), {
  ssr: false
});
