import { useQuery, UseQueryOptions } from 'react-query';

import { axiosCooperativeServiceInstance } from './../api/axios.cooperativeServiceConfig';

interface CompanyListInterface {
  clientId: string;
  id: string;
  name: string;
  teamLeader: string;
  teamMembersCount: number;
}

const getCompanyList = async (): Promise<APIResponse<Array<CompanyListInterface>>> => {
  const { data } = await axiosCooperativeServiceInstance.get('/references/userTeams', {
    params: {
      clientId: process.env.DEFAULT_COMPANY_ID
    }
  });
  return data;
};

export default function useGetCompanyList({
  options
}: {
  options?: UseQueryOptions<APIResponse<Array<CompanyListInterface>>>;
}) {
  return useQuery<APIResponse<Array<CompanyListInterface>>>('companyList', getCompanyList, options);
}
